import {
	getInitialLocationSelection,
	retrieveCacheOrSampleLocation
} from '$lib/constants/location-selection-data';
import type { AutocompletePrediction } from '$types/autocomplete.types';
import { writable } from 'svelte/store';

export interface LocationSelection {
	pickup: AutocompletePrediction | undefined;
	dropoff: AutocompletePrediction | undefined;
	isValid: boolean;
	expiryTime: number;
}

export const locationSelection = writable<LocationSelection>(retrieveCacheOrSampleLocation());

if (typeof window !== 'undefined') {
	locationSelection.set(retrieveCacheOrSampleLocation());
}

export const update = (data: LocationSelection) => {
	locationSelection.set(data);
	if (typeof window !== 'undefined') {
		data.expiryTime = Date.now() + 24 * 60 * 60 * 1000;
		sessionStorage.setItem('_l', JSON.stringify(data));
	}
};

const updatePersistStorage = (locationSelection: LocationSelection) => {
	if (typeof window !== 'undefined') {
		locationSelection.expiryTime = Date.now() + 2 * 60 * 60 * 1000;
		sessionStorage.setItem('_l', JSON.stringify(locationSelection));
	}
};

locationSelection.subscribe((locationSelection) => {
	if (locationSelection) {
		updatePersistStorage(locationSelection);
	}
});

// Function to swap the location selection values.
export const swapLocationSelection = () => {
	locationSelection.update((locationSelectionValue) => {
		[locationSelectionValue.pickup, locationSelectionValue.dropoff] = [
			locationSelectionValue.dropoff,
			locationSelectionValue.pickup
		];
		return locationSelectionValue;
	});
};

export const resetLocationSelection = () => {
	locationSelection.update(() => getInitialLocationSelection());
};

export const resetFrom = () => {
	locationSelection.update((locationSelectionValue) => {
		locationSelectionValue.pickup = getInitialLocationSelection().pickup;
		return locationSelectionValue;
	});
};

export const resetTo = () => {
	locationSelection.update((locationSelectionValue) => {
		locationSelectionValue.dropoff = getInitialLocationSelection().dropoff;
		return locationSelectionValue;
	});
};
