import type { LocationSelection } from '$store/locationSelection';

export const getInitialLocationSelection = (): LocationSelection => ({
	pickup: {
		place_id: '',
		structured_formatting: { main_text: '', secondary_text: '' },
		type: ''
	},
	dropoff: {
		place_id: '',
		structured_formatting: { main_text: '', secondary_text: '' },
		type: ''
	},
	isValid: false,
	expiryTime: Date.now() + 60 * 1000 * 10
});

export function retrieveCacheOrSampleLocation(loadSessionStorage = true): LocationSelection {
	let locationSelection: LocationSelection;
	if (loadSessionStorage) {
		if (typeof window !== 'undefined') {
			const locationString = sessionStorage.getItem('_l');
			if (locationString) {
				locationSelection = JSON.parse(locationString);
				if (locationSelection.expiryTime < Date.now() || !locationSelection.expiryTime) {
					sessionStorage.removeItem('_l');
				}
				return locationSelection;
			}
		}
	}
	locationSelection = getInitialLocationSelection();
	return locationSelection;
}
